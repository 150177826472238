
































import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'HiringCompleted' })
export default class HiringCompleted extends Vue {
  login_page_route = process.env.VUE_APP_COLUMBUS_WEB_LOGIN_URL;

  created() {
    this.$emit('hideSteps');
  }
}
